var $ = require( 'jquery' );
var DataTable = require( 'datatables.net-zf' );

require('./jquery.browser.js');
require('./jquery.cycle.js');
require('jquery.easing');
// var DataTable = require('datatables.net-zf');

// require('./jquery.uniform.min.js');
//require('../foundation/js/vendor/foundation.min');
// require('../../assets/foundation-min/js/vendor/foundation.min');
require('foundation-sites/dist/js/foundation.min');
// initialize Foundation
$(document).foundation();

// Run this code when DOM is loaded
$(document).ready(function () {

    $('form').preventDoubleSubmission();

    // mobile navigation main toggle
    $("#cmdMobileMenu").click(function () {
        var $menu = $("#mobileNav");
        $(this).toggleClass("is-active");
        $menu.slideToggle(300);
    });

    // Open Modals
    $(document).on("click", "[data-popup-open]", function(a){
        var b = $(this).attr("data-popup-open");
        $('[data-popup="' + b + '"]').fadeIn(350);
        a.preventDefault()
    })

    // Close Modals
    $(document).on("click", "[data-popup-close]", function(a) {
        var b = $(this).attr("data-popup-close");
        $('[data-popup="' + b + '"]').fadeOut(350);
        $('form').data('submitted', false);
        a.preventDefault()
    });

    // Look for required fields and add a * to the corresponding label


    // Select all labels with class "required"
    var labels = document.querySelectorAll('label.required');

// Loop through each label
    for (var i = 0; i < labels.length; i++) {
        // Check if the label already contains a <sup>*</sup>
        if (!labels[i].innerHTML.includes('<sup>*</sup>')) {
            // If not, append <sup>*</sup> to the label
            labels[i].innerHTML += '<sup>*</sup>';
        }
    }


    // Do DataTable sorting if .datatable-sortable exists
    if($('.datatable-sortable').length >= 1) {
        $('.datatable-sortable').DataTable({
            retrieve: true,
            lengthMenu: false
        });
    }

});



/**
 * jQuery plugin to prevent double submission of forms
 *
 * https://stackoverflow.com/questions/2830542/prevent-double-submission-of-forms-in-jquery
 *
 * @returns {jQuery}
 */
jQuery.fn.preventDoubleSubmission = function() {
    $(this).on('submit',function(e){
        var $form = $(this);

        if ($form.data('submitted') === true) {
            // Previously submitted - don't submit again
            console.log('Form double click submission blocked.');
            e.preventDefault();
        } else {
            // Mark it so that the next submit can be ignored
            $form.data('submitted', true);
        }
    });

    // Keep chainability
    return this;
};